<template>
    <div>
        <form class="form-horizontal" @submit.prevent="updatePassword">
            <div class="row">
                <div class="col-lg-4">
                    <div class="form-group">
                        <label for="ownerfname">Current Password*</label>
                        <input
                            v-model="pass.current_password"
                            type="password"
                            class="form-control mb-3"
                            id="username"
                            :class="{
                                'is-invalid':
                                    passwordSubmitted &&
                                    $v.pass.current_password.$error,
                            }"
                        />
                        <div
                            v-if="
                                passwordSubmitted &&
                                $v.pass.current_password.$error
                            "
                            class="invalid-feedback"
                        >
                            <span v-if="!$v.pass.current_password.required"
                                >Current password is required</span
                            >
                        </div>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="form-group">
                        <label for="ownerfname">New Password*</label>
                        <input
                            v-model="pass.password"
                            type="password"
                            class="form-control mb-3"
                            id="username"
                            :class="{
                                'is-invalid':
                                    passwordSubmitted &&
                                    $v.pass.password.$error,
                            }"
                        />
                        <div
                            v-if="passwordSubmitted && $v.pass.password.$error"
                            class="invalid-feedback"
                        >
                            <span v-if="!$v.pass.password.required"
                                >Current password is required</span
                            >
                            <span v-if="!$v.pass.password.minLength"
                                >Should be of atleast 8 characters</span
                            >
                        </div>
                    </div>
                </div>

                <div class="col-lg-4">
                    <div class="form-group">
                        <label for="ownerfname">Confirm New Password*</label>
                        <input
                            v-model="pass.confirm_password"
                            type="password"
                            class="form-control mb-3"
                            id="username"
                            :class="{
                                'is-invalid':
                                    passwordSubmitted &&
                                    $v.pass.confirm_password.$error,
                            }"
                        />
                        <div
                            v-if="
                                passwordSubmitted &&
                                $v.pass.confirm_password.$error
                            "
                            class="invalid-feedback"
                        >
                            <span v-if="!$v.pass.confirm_password.required"
                                >Password is required</span
                            >
                            &nbsp;&nbsp;
                            <span
                                v-if="!$v.pass.confirm_password.sameAsPassword"
                                >Passwords must match</span
                            >
                        </div>
                    </div>
                </div>
            </div>
            <submitButton />
        </form>
    </div>
</template>
<script>
import { required, minLength, sameAs } from "vuelidate/lib/validators";
import submitButton from "../../../components/submitButton.vue";
import { BASE_URL } from "../../common";
import axios from "axios";
import alertMixin from "../../../mixins/alertMixin";

export default {
    mixins: [alertMixin],
    validations: {
        pass: {
            current_password: { required },
            password: { required, minLength: minLength(8) },
            confirm_password: { required, sameAsPassword: sameAs("password") },
        },
    },
    components: {
        submitButton,
    },
    data() {
        return {
            passwordSubmitted: false,
            pass: {
                current_password: "",
                password: "",
                confirm_password: "",
            },
        };
    },
    methods: {
        updatePassword() {
            console.log("trying to update password");
            this.passwordSubmitted = true;
            this.$v.$touch();

            if (!this.$v.$error) {
                console.log("success");
                if (localStorage.cs_user_token) {
                    let loader = this.$loading.show({
                        container: this.fullPage
                            ? null
                            : this.$refs.formContainer,
                        canCancel: false,
                        onCancel: this.onCancel,
                    });
                    let payload = {
                        current_password: this.pass.current_password,
                        password: this.pass.password,
                    };
                    axios
                        .post(
                            `${BASE_URL}user/changepassword?token=${localStorage.cs_user_token}`,
                            payload
                        )
                        .then((res) => {
                            console.log(res);
                            if (res.data.status_code == 200) {
                                this.passwordSubmitted = false;
                                this.pass.password = "";
                                this.pass.current_password = "";
                                this.pass.confirm_password = "";
                                this.successPopUp(
                                    "Successfully updated password"
                                );
                                // this.errorMsg("Coudnot update password");
                            } else if (res.data.status_code == 501) {
                                this.errorMsg("Unable to update password");
                            }

                            this.fetchProfileDetails();
                        })
                        // eslint-disable-next-line no-unused-vars
                        .catch((err) => {
                            loader.hide();
                        });
                }
            }
        },
    },
};
</script>
