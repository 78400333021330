<template>
    <Layout>
        <b-tabs
            content-class="mt-3"
            active-nav-item-class="font-weight-bold text-uppercase text-primary"
            active-tab-class="font-weight-bold text-dark"
            v-model="step"
        >
            <b-tab title="Update Profile">
                <form class="form-horizontal" @submit.prevent="updateProfile">
                    <div class="row">
                        <div class="col-lg-3">
                            <div class="form-group">
                                <label for="ownerfname">Name</label>
                                <input
                                    v-model="name"
                                    type="text"
                                    class="form-control mb-3"
                                    id="username"
                                    disabled
                                />
                            </div>
                        </div>
                        <div class="col-lg-3">
                            <div class="form-group">
                                <label for="ownerfname">Nationality</label>
                                <b-form-select
                                    id="ownerfname"
                                    name="ownerfname"
                                    v-model="profile.nationality"
                                    :options="mappedCountries"
                                    :class="{
                                        'is-invalid':
                                            submitted &&
                                            $v.profile.nationality.$error,
                                    }"
                                ></b-form-select>
                                <div
                                    v-if="
                                        submitted &&
                                        $v.profile.nationality.$error
                                    "
                                    class="invalid-feedback"
                                >
                                    <span
                                        v-if="!$v.profile.nationality.required"
                                        >Nationality is required</span
                                    >
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3">
                            <div class="form-group">
                                <label for="ownerfname">Address</label>
                                <input
                                    v-model="profile.address"
                                    type="text"
                                    class="form-control mb-3"
                                    id="username"
                                    :class="{
                                        'is-invalid':
                                            submitted &&
                                            $v.profile.address.$error,
                                    }"
                                />
                                <div
                                    v-if="
                                        submitted && $v.profile.address.$error
                                    "
                                    class="invalid-feedback"
                                >
                                    <span v-if="!$v.profile.address.required"
                                        >Address is required</span
                                    >
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-3">
                            <div class="form-group">
                                <label for="ownerfname">Phone</label>
                                <input
                                    v-model="profile.phone"
                                    type="text"
                                    class="form-control mb-3"
                                    :class="{
                                        'is-invalid':
                                            submitted &&
                                            $v.profile.mobile.$error,
                                    }"
                                    id="username"
                                />
                                <div
                                    v-if="submitted && $v.profile.mobile.$error"
                                    class="invalid-feedback"
                                >
                                    <span v-if="!$v.profile.mobile.numeric"
                                        >Only numeric values allowed</span
                                    >
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3">
                            <div class="form-group">
                                <label for="ownerfname">Fax</label>
                                <input
                                    v-model="profile.fax"
                                    type="text"
                                    class="form-control mb-3"
                                    id="username"
                                />
                            </div>
                        </div>
                        <div class="col-lg-3">
                            <div class="form-group">
                                <label for="ownerfname">Mobile</label>
                                <input
                                    v-model="profile.mobile"
                                    type="text"
                                    class="form-control mb-3"
                                    id="username"
                                    :class="{
                                        'is-invalid':
                                            submitted &&
                                            $v.profile.mobile.$error,
                                    }"
                                />
                                <div
                                    v-if="submitted && $v.profile.mobile.$error"
                                    class="invalid-feedback"
                                >
                                    <span v-if="!$v.profile.mobile.required"
                                        >Mobile number is required</span
                                    >
                                    <span v-if="!$v.profile.mobile.numeric"
                                        >Only numeric values allowed</span
                                    >
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-lg-3">
                            <div class="form-group">
                                <label for="ownerfname">Passport Number</label>
                                <input
                                    v-model="profile.passport_no"
                                    type="text"
                                    class="form-control mb-3"
                                    :class="{
                                        'is-invalid':
                                            submitted &&
                                            $v.profile.passport_no.$error,
                                    }"
                                    id="username"
                                />
                                <div
                                    v-if="
                                        submitted &&
                                        $v.profile.passport_no.$error
                                    "
                                    class="invalid-feedback"
                                >
                                    <span
                                        v-if="!$v.profile.passport_no.required"
                                        >Passport number is required</span
                                    >
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3">
                            <div class="form-group">
                                <label for="ownerfname">Emirates Id</label>
                                <input
                                    v-model="profile.eid_no"
                                    type="text"
                                    class="form-control mb-3"
                                    v-mask="'###-####-#######-#'"
                                    :class="{
                                        'is-invalid':
                                            submitted &&
                                            $v.profile.eid_no.$error,
                                    }"
                                    id="username"
                                />
                                <div
                                    v-if="submitted && $v.profile.eid_no.$error"
                                    class="invalid-feedback"
                                >
                                    <span v-if="!$v.profile.eid_no.required"
                                        >Emirates id number is required</span
                                    >
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3">
                            <div class="form-group">
                                <label for="ownerfname">PO Box</label>
                                <input
                                    v-model="profile.po_box"
                                    type="text"
                                    class="form-control mb-3"
                                    id="username"
                                    :class="{
                                        'is-invalid':
                                            submitted &&
                                            $v.profile.po_box.$error,
                                    }"
                                />
                                <div
                                    v-if="submitted && $v.profile.po_box.$error"
                                    class="invalid-feedback"
                                >
                                    <span v-if="!$v.profile.po_box.required"
                                        >PO box is required</span
                                    >
                                    <span v-if="!$v.profile.po_box.numeric"
                                        >Only numeric values allowed</span
                                    >
                                </div>
                            </div>
                        </div>
                    </div>

                    <submitButton />
                </form>
            </b-tab>
            <b-tab title=" Update Profile Pic">
                <b-card
                    header-class="bg-transparent border-primary"
                    class="border border-primary"
                >
                    <img
                        v-if="!profilePic && !pic.profile_pic"
                        src="../../../assets/images/users/profilepic.png"
                        alt=""
                        style="width: 100%"
                    />
                    <img
                        :src="`data:image/png;base64,${profilePic}`"
                        v-if="profilePic && !pic.profile_pic"
                        alt="Profile Picture"
                        style="width: 100%"
                    />
                    <img
                        v-if="pic.profile_pic"
                        class="profile_pic"
                        :src="`data:image/png;base64,${pic.profile_pic}`"
                        alt="Profile Picture"
                        style="width: 100%"
                    />
                    <h4>{{ name }}</h4>
                    <input
                        type="file"
                        ref="file"
                        hidden
                        id="fileUpload"
                        @change="handleProfilePic"
                    />
                    <p v-if="profilePic || !pic.profile_pic || !profilePic">
                        <button @click="$refs.file.click()">
                            Upload a new pic
                        </button>
                    </p>
                    <p v-if="pic.profile_pic">
                        <button
                            class="btn btn-primary"
                            @click="updateProfilePic"
                        >
                            Update Picture
                        </button>
                    </p>
                </b-card>
            </b-tab>
            <b-tab
                title="Update Documents"
                v-if="userRole == 'Owner' || userRole == 'Tenant'"
            >
                <div class="row mt-5 mb-5">
                    <div class="col-lg-4">
                        <button
                            @click="viewDocument(passport)"
                            class="btn btn-primary"
                        >
                            View Passport
                        </button>
                    </div>
                    <div class="col-lg-4">
                        <button
                            @click="viewDocument(visa)"
                            class="btn btn-primary"
                        >
                            View Visa
                        </button>
                    </div>
                    <div class="col-lg-4">
                        <button
                            @click="viewDocument(eid)"
                            class="btn btn-primary"
                        >
                            View Emirates Id
                        </button>
                    </div>
                </div>
                <form class="form-horizontal" @submit.prevent="updateDocs">
                    <div class="row">
                        <div class="col-lg-4">
                            <div class="form-group">
                                <label for="ownerfname">Passport Copy*</label>
                                <b-form-file
                                    placeholder="Choose File"
                                    drop-placeholder="Drop file here..."
                                    @change="handlePassport"
                                    class="mb-3"
                                    accept="image/png, image/gif, image/jpeg,application/pdf"
                                ></b-form-file>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="form-group">
                                <label for="ownerfname">Visa Copy*</label>
                                <b-form-file
                                    placeholder="Choose File"
                                    drop-placeholder="Drop file here..."
                                    @change="handleVisa"
                                    class="mb-3"
                                    accept="image/png, image/gif, image/jpeg,application/pdf"
                                ></b-form-file>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="form-group">
                                <label for="ownerfname"
                                    >Emirates Id Copy*</label
                                >
                                <b-form-file
                                    placeholder="Choose File"
                                    drop-placeholder="Drop file here..."
                                    @change="handleEid"
                                    class="mb-3"
                                    accept="image/png, image/gif, image/jpeg,application/pdf"
                                ></b-form-file>
                            </div>
                        </div>
                    </div>
                    <submitButton />
                </form>
            </b-tab>
            <b-tab title="Reset Password">
                <resetPassword />
            </b-tab>
        </b-tabs>
    </Layout>
</template>
<script>
import Layout from "../../layouts/main.vue";
import { BASE_URL } from "../../common";
import axios from "axios";
import submitButton from "../../../components/submitButton.vue";
import { required, numeric } from "vuelidate/lib/validators";
import alertMixin from "../../../mixins/alertMixin";
import unitMixin from "../../../mixins/unitMixin";
import resetPassword from "./updatePassword.vue";
export default {
    mixins: [alertMixin, unitMixin],
    mounted() {
        // this.step = this.$route.params.id;
        console.log("the step is ", this.step);
        this.$store.dispatch("apidata/fetchCountries");
        this.fetchProfileDetails();
        // this.$store.dispatch(
        //     "apidata/fetchUserInfo",
        //     localStorage.cs_user_token
        // );
        this.userRole = localStorage.cs_user_role;
    },
    validations: {
        profile: {
            address: { required },
            eid_no: { required },
            mobile: { required, numeric },
            nationality: { required },
            passport_no: { required },
            phone: { numeric },
            po_box: { required, numeric },
        },
    },
    components: {
        Layout,
        submitButton,
        resetPassword,
    },
    data() {
        return {
            // step: null,
            userRole: null,
            submitted: false,
            docSubmitted: false,
            passwordSubmitted: false,
            passport: null,
            visa: null,
            eid: null,
            name: "",
            con: "",
            profile: {
                address: "",
                eid_no: "",
                fax: "",
                mobile: "",
                nationality: "",
                passport_no: "",
                phone: "",
                po_box: "",
            },
            pic: {
                profile_pic: "",
                profile_pic_filename: "",
            },
            docs: {
                passport: "",
                passport_filename: "",
                visa: "",
                visa_filename: "",
                eid: "",
                eid_filename: "",
            },
            pass: {
                current_password: "",
                password: "",
                confirm_password: "",
            },
        };
    },
    computed: {
        step() {
            return this.$route.params.id;
        },
        countries() {
            return this.$store.getters["apidata/getCountries"];
        },
        mappedCountries() {
            return this.countries.map((ele) => {
                return {
                    value: ele.id,
                    text: ele.name,
                };
            });
        },
        profilePic() {
            return this.$store.getters["apidata/getProfileImage"];
        },
    },
    methods: {
        updateDocs() {
            this.docSubmitted = true;

            console.log(this.docs);
            if (
                this.docs.passport === "" &&
                this.docs.visa === "" &&
                this.docs.eid === ""
            ) {
                return;
            }
            if (localStorage.cs_user_token) {
                let loader = this.$loading.show({
                    container: this.fullPage ? null : this.$refs.formContainer,
                    canCancel: false,
                    onCancel: this.onCancel,
                });
                axios
                    .post(
                        `${BASE_URL}user/update-documents?token=${localStorage.cs_user_token}`,
                        this.docs
                    )
                    .then((res) => {
                        console.log(res);
                        this.fetchProfileDetails();
                        (this.docs.passport = ""),
                            (this.docs.passport_filename = ""),
                            (this.docs.visa = ""),
                            (this.docs.visa_filename = ""),
                            (this.docs.eid = ""),
                            (this.docs.eid_filename = ""),
                            this.docs.loader.hide();
                        this.successPopUp("Successfully updloaded documents");
                    })
                    // eslint-disable-next-line no-unused-vars
                    .catch((err) => {
                        loader.hide();
                    });
            }
        },
        updateProfile() {
            console.log("profile update clicked");
            this.submitted = true;
            this.$v.$touch();
            if (this.$v.$error) {
                return;
            }

            if (!this.$v.$error) {
                console.log(this.profile);
                if (localStorage.cs_user_token) {
                    let loader = this.$loading.show({
                        container: this.fullPage
                            ? null
                            : this.$refs.formContainer,
                        canCancel: false,
                        onCancel: this.onCancel,
                    });
                    axios
                        .post(
                            `${BASE_URL}user/updateProfile?token=${localStorage.cs_user_token}`,
                            this.profile
                        )
                        .then((res) => {
                            console.log(res);
                            loader.hide();
                            this.successPopUp(
                                "Successfully updated profile information"
                            );
                            this.fetchProfileDetails();
                        })
                        // eslint-disable-next-line no-unused-vars
                        .catch((err) => {
                            loader.hide();
                        });
                }
            }
        },
        updateProfilePic() {
            if (localStorage.cs_user_token) {
                let loader = this.$loading.show({
                    container: this.fullPage ? null : this.$refs.formContainer,
                    canCancel: false,
                    onCancel: this.onCancel,
                });

                axios
                    .post(
                        `${BASE_URL}user/update-profile-pic?token=${localStorage.cs_user_token}`,
                        this.pic
                    )
                    .then((res) => {
                        this.pic.profile_pic = "";
                        this.pic.profile_pic_filename = "";
                        console.log(res);
                        loader.hide();

                        this.successPopUp(
                            "Successfully uploded a new profile picture"
                        );
                        this.fetchProfileDetails();
                    })
                    // eslint-disable-next-line no-unused-vars
                    .catch((err) => {
                        loader.hide();
                    });
            }
        },
        fetchProfileDetails() {
            if (localStorage.cs_user_token) {
                axios
                    .get(
                        `${BASE_URL}getprofile?token=${localStorage.cs_user_token}`
                    )
                    .then((res) => {
                        this.$store.dispatch(
                            "apidata/fetchProfileImage",
                            res.data.user_info.profile_pic_url
                        );
                        console.log(res.data.user_info);
                        this.documents = res.data.user_info.documents;
                        this.passport = res.data.user_info.documents.passport;
                        this.visa = res.data.user_info.documents.visa;
                        this.eid = res.data.user_info.documents.eid;

                        this.name = res.data.user_info.name;
                        this.profile.address = res.data.user_info.address;
                        this.profile.eid_no = res.data.user_info.eid_no;
                        this.profile.fax = res.data.user_info.fax;
                        this.profile.mobile = res.data.user_info.mobile;
                        this.profile.nationality =
                            res.data.user_info.nationality;
                        this.profile.passport_no =
                            res.data.user_info.passport_no;
                        this.profile.phone = res.data.user_info.phone;
                        this.profile.po_box = res.data.user_info.po_box;
                    });
            }
        },

        handleProfilePic(e) {
            let reader = new FileReader();
            this.pic.profile_pic_filename = e.target.files[0].name;
            reader.onload = (e) => {
                this.pic.profile_pic = e.target.result.split(",")[1];
                console.log(this.pic.profile_pic);
            };
            reader.readAsDataURL(e.target.files[0]);
        },

        handlePassport(e) {
            let reader = new FileReader();
            this.docs.passport_filename = e.target.files[0].name;
            reader.onload = (e) => {
                this.docs.passport = e.target.result.split(",")[1];
            };
            reader.readAsDataURL(e.target.files[0]);
        },

        handleVisa(e) {
            let reader = new FileReader();
            this.docs.visa_filename = e.target.files[0].name;
            reader.onload = (e) => {
                this.docs.visa = e.target.result.split(",")[1];
            };
            reader.readAsDataURL(e.target.files[0]);
        },

        handleEid(e) {
            let reader = new FileReader();
            this.docs.eid_filename = e.target.files[0].name;
            reader.onload = (e) => {
                this.docs.eid = e.target.result.split(",")[1];
            };
            reader.readAsDataURL(e.target.files[0]);
        },
    },
};
</script>
<style scoped>
.card {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    max-width: 300px;
    margin: auto;
    text-align: center;
    font-family: arial;
}

.title {
    color: grey;
    font-size: 18px;
}

button {
    border: none;
    outline: 0;
    display: inline-block;
    padding: 8px;
    color: white;
    background-color: #1d285c;
    text-align: center;
    cursor: pointer;
    width: 100%;
    font-size: 18px;
}

a {
    text-decoration: none;
    font-size: 22px;
    color: black;
}

button:hover,
a:hover {
    opacity: 0.7;
}
</style>
