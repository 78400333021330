var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('form',{staticClass:"form-horizontal",on:{"submit":function($event){$event.preventDefault();return _vm.updatePassword.apply(null, arguments)}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-4"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"ownerfname"}},[_vm._v("Current Password*")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.pass.current_password),expression:"pass.current_password"}],staticClass:"form-control mb-3",class:{
                            'is-invalid':
                                _vm.passwordSubmitted &&
                                _vm.$v.pass.current_password.$error,
                        },attrs:{"type":"password","id":"username"},domProps:{"value":(_vm.pass.current_password)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.pass, "current_password", $event.target.value)}}}),(
                            _vm.passwordSubmitted &&
                            _vm.$v.pass.current_password.$error
                        )?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.pass.current_password.required)?_c('span',[_vm._v("Current password is required")]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"col-lg-4"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"ownerfname"}},[_vm._v("New Password*")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.pass.password),expression:"pass.password"}],staticClass:"form-control mb-3",class:{
                            'is-invalid':
                                _vm.passwordSubmitted &&
                                _vm.$v.pass.password.$error,
                        },attrs:{"type":"password","id":"username"},domProps:{"value":(_vm.pass.password)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.pass, "password", $event.target.value)}}}),(_vm.passwordSubmitted && _vm.$v.pass.password.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.pass.password.required)?_c('span',[_vm._v("Current password is required")]):_vm._e(),(!_vm.$v.pass.password.minLength)?_c('span',[_vm._v("Should be of atleast 8 characters")]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"col-lg-4"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"ownerfname"}},[_vm._v("Confirm New Password*")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.pass.confirm_password),expression:"pass.confirm_password"}],staticClass:"form-control mb-3",class:{
                            'is-invalid':
                                _vm.passwordSubmitted &&
                                _vm.$v.pass.confirm_password.$error,
                        },attrs:{"type":"password","id":"username"},domProps:{"value":(_vm.pass.confirm_password)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.pass, "confirm_password", $event.target.value)}}}),(
                            _vm.passwordSubmitted &&
                            _vm.$v.pass.confirm_password.$error
                        )?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.pass.confirm_password.required)?_c('span',[_vm._v("Password is required")]):_vm._e(),_vm._v("    "),(!_vm.$v.pass.confirm_password.sameAsPassword)?_c('span',[_vm._v("Passwords must match")]):_vm._e()]):_vm._e()])])]),_c('submitButton')],1)])}
var staticRenderFns = []

export { render, staticRenderFns }